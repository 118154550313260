//Converts timespan into array
export const TimeSpanToArray = (span) => {
	let arr = [0, 0, 0, 0, 0];
	const split = span.split(':');
	const dH = split[0].split('.').reverse();
	const sMs = split[2].split('.');

	if (dH[1]) arr[0] = dH[1]; //hours
	arr[1] = dH[0]; //hours
	arr[2] = split[1]; //minutes
	arr[3] = sMs[0]; //seconds
	if (sMs[1]) arr[4] = `0.${sMs[1]}`; //milliseconds

	arr = arr.map((el) => Number(el));
	return arr;
}

//Converts array into timespan
export const ArrayToTimeSpan = (arr) => {
	return `${arr[0] > 0 ? `${arr[0]}.` : ''}${arr
		.filter((v, i) => i < 4 && i > 0)
		.map((v) => v < 10 ? (v === 0 ? `00` : `0${v}`) : v)
		.join(':')
		}${arr[4] > 0 ? `.${String(arr[4]).substring(2)}` : ''}`
}
