import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

const ModalResult = ({ t, state, error, onClose }) => {
	return (
		<Fragment>
			{state === "loading" && <div className="modal-body d-flex justify-content-center">
				<div className="spinner-border text-primary" role="status" />
			</div >}
			{state === "error" && <Fragment>
				<div className="modal-body" role="status">
					<p className="text-danger">{t(`${error}`)}</p>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => onClose()}>{t('ok')}</button>
				</div>
			</Fragment>}
		</Fragment>
	);
}

export default withTranslation()(ModalResult);
