import React, { Fragment } from 'react';
import { useAuth } from "react-oidc-context";
import { useTranslation } from 'react-i18next';
import ModalResult from './ModalResultFragment';

const FileDeleteModal = ({ file, onClose }) => {
	const auth = useAuth();
	const { t } = useTranslation();
	const [state, setState] = React.useState("ready");
	const [error, setError] = React.useState(null);

	const deleteFile = async () => {
		setState("loading");
		try {
			const token = auth.user?.access_token;
			const response = await fetch(`/arm/audio-file/` + file, {
				method: 'DELETE',
				headers: { "Authorization": "Bearer " + token }
			});
			if (response.ok) { onClose(true); }
			else switch (response.status) {
				case 404: throw new Error("errorDeleteFileNotFound");
				default: throw new Error("errorDeleteFile");
			} 
		} catch (e) { setState("error"); setError(e); }
	}

	return (
		<div className="modal" onClick={onClose}>
			<div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t('warning')}</h5>
					</div>
					{state === "ready" &&
						<Fragment>
							<div className="modal-body">{t('areYouSureDelete')}</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => onClose()} >{t('cancel')}</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={() => { deleteFile() }} >{t('delete')}</button>
							</div>	
						</Fragment>
					}
					<ModalResult
						state={state}
						error={error}
						onClose={onClose} />
				</div>
			</div>
		</div>
	);
}

export default FileDeleteModal;
