import 'bootstrap/dist/css/bootstrap.css';
import './custom.css';
import './components/translations/i18n';

import ReactDOM from 'react-dom';
import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import AuthContext from './AuthContext';

const rootElement = document.getElementById('root');

ReactDOM.render(
		<AuthContext>
			<App />
		</AuthContext>,
	rootElement
);

serviceWorkerRegistration.unregister();
reportWebVitals();
