import React, { Fragment } from 'react';
import { useAuth } from "react-oidc-context";
import { useTranslation } from 'react-i18next';
import ModalResult from './ModalResultFragment';

const FileAddModal = ({ onClose }) => {
	const auth = useAuth();
	const { t } = useTranslation();
	const [state, setState] = React.useState("ready");
	const [error, setError] = React.useState(null);
	const [post, setPost] = React.useState(true);
	const [files, setFiles] = React.useState([]);
	const [filesError, setFilesError] = React.useState(null);

	const addFiles = async () => {
		if (files.length > 0) {
			setState("loading");
			try {
				const token = auth.user?.access_token;
				const data = new FormData();
				[...files].forEach((file, i) => {
					// the name has to be 'files' so that .NET could properly bind it
					data.append(`files`, file, file.name);
				});
				const response = await fetch(`/arm/audio-file?postprocessing=${post}`, {
					method: 'POST',
					headers: { "Authorization": "Bearer " + token },
					body: data
				});
				if (response.ok) { onClose(true); }
				else switch (response.status) {
					case 400: throw new Error("errorAddFileNoFiles");
					case 403: throw new Error("errorUserDisabled");
					default: throw new Error("errorAddFile");
				}
			} catch (e) { setState("error"); setError(e); }
		} else setFilesError(t('noFilesSelected'));
	}

	return (
		<div className="modal" onClick={() => onClose()}>
			<div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t('addFiles')}</h5>
					</div>
					{state === "ready" &&
						<Fragment>
							<div className="modal-body">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										id="lang-select"
										onChange={(e) => { setPost(!post); }}
										checked={post} />
									<label
										className="form-check-label"
										htmlFor="post-select">
										{t('postProcessing')}
									</label>
								</div>
								<br />
								<label htmlFor="fileForm" className="form-label">{t('chooseFilesToUpload')}:</label>
								<input
									className={`form-control ${filesError ? 'is-invalid' : ''}`}
									type="file"
									id="fileForm"
									multiple
									onChange={(ev) => { setFiles(ev.target.files); setFilesError(null); }} />
								{filesError && <small className="text-danger">{filesError}</small>}
								{files.length !== 0 &&
									<div>
										<br />
										<label htmlFor="selectedFiles" className="label">{t('selectedFiles')}:</label>
										<ul id="selectedFiles" className="list-group">
											{[...files].map((file) => (
												<li
													key={file.name}
													className="list-group-item">
													{file.name}
												</li>
											))}
										</ul>
									</div>
								}
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => onClose()}>{t('cancel')}</button>
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => addFiles()}>{t('add')}</button>
							</div>
						</Fragment>
					}
					<ModalResult
						state={state}
						error={error}
						onClose={onClose} />
				</div>
			</div>
		</div>
	);
};

export default FileAddModal;
