import React, { Fragment } from 'react';
import ModalResult from './ModalResultFragment';
import TimeInput from '../UI/TimeInput';
import { ArrayToTimeSpan } from '../Util/TimeSpanFunctions';
import { useTranslation } from 'react-i18next';
import { useAuth } from "react-oidc-context";

const UserCreditModal = ({ user, onClose }) => {
	const auth = useAuth();
	const { t } = useTranslation();
	const [state, setState] = React.useState("ready");
	const [error, setError] = React.useState(null);
	const [inputCredits, setInputCredits] = React.useState([0, 0, 0, 0, 0]);

	const addCredits = async () => {
		setState("loading");
		try {
			const token = auth.user?.access_token;
			const response = await fetch(`/user/${user.id}/Credit/Add`, {
				method: 'PUT',
				headers: {
					"Authorization": "Bearer " + token,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					credits: ArrayToTimeSpan(inputCredits),
					id: user.id
				})
			});
			if (response.ok) onClose(true);
			else switch (response.status) {
				case 404: throw new Error("errorAddCreditNotFound");
				default: throw new Error("errorAddCredit");
			}
		} catch (e) { setState("error"); setError(e); }
	}

	return (
		<div className="modal" onClick={onClose}>
			<div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t('addCredits')}</h5>
					</div>
					{state === "ready" && <Fragment>
						<div className="modal-body">
							<form>
								<TimeInput
									credits={inputCredits}
									onChange={(cr) => { setInputCredits(cr); console.log("input " + cr) }} />
							</form>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => onClose()} >{t('cancel')}</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => addCredits() } >{t('add')}</button>
						</div>
					</Fragment>}
					<ModalResult
						state={state}
						error={error}
						onClose={onClose} />
				</div>
			</div>
		</div>
	);
}

export default UserCreditModal;
