import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Cookies from 'js-cookie';

const Footer = () => {
	const { t, i18n } = useTranslation();
	const lang = i18n.resolvedLanguage;
	const contrast = Cookies.get("contrast") === 'true' ? "white" : "black";

	return (
		<footer>
			<div className="fund">
				<span className="fund_fe"><img alt="FE logotype" src={require(`../images/fe_${lang}_${contrast}.png`)} /></span>
				<span className="fund_rp"><img alt="RP logotype" src={require(`../images/rp_${lang}_${contrast}.png`)} /></span>
				<span className="fund_ue"><img alt="EU logotype" src={require(`../images/eu_${lang}_${contrast}.png`)} /></span>
			</div>

			<div className="site-footer">	
				<Container className="site-info">
					<img
						className="dariah-footer-logo"
						alt="Lab dariah footer logo"
						src={require(`../images/logo_white.png`)} />

					<nav id="footer-navigation">
						<ul>
							<li>
								<a href="https://lab.dariah.pl/deklaracja-dostepnosci/">
									{t('accessibilityStatement')}
								</a>
							</li>
							<li>
								<a href="https://lab.dariah.pl/polityka-prywatnosci/">
									{t('privacyPolicy')}
								</a>
							</li>
						</ul>
					</nav>

					<a className="pcss-wrapper" href="https://www.pcss.pl/">
						<div className="pcss-info">
							<img alt="PCSS logotype" src={require(`../images/pcss_${lang}.png`)} />
							<span>Copyright © 2024 PCSS<br />{t('pcss')}</span>
						</div>
					</a>

					<div className="social-footer">
						<a className="social-fb ib ib-fa-facebook" href="https://www.facebook.com/dariah.lab" aria-label="facebook"></a>
						<a className="social-yt ib ib-whh-youtube" href="https://www.youtube.com/channel/UCw8VTPn8_nyWS3zi5yGUfQg" aria-label="youtube"></a>
					</div>
				</Container>
			</div>
		</footer>
	);
}

export default Footer;