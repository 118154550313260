import Cookies from 'js-cookie';
import { FontIcon, ContrastIcon } from './Icons';

const AccessibilityToolbar = () => {
	if (Cookies.get("bigfont") === "true") document.body.classList.add('fontsize');
	if (Cookies.get("contrast") === "true") document.body.classList.add('contrast');

	const turnBigFont = () => {
		if (Cookies.get("bigfont") === "true") {
			document.body.classList.remove('fontsize');
			Cookies.set('bigfont', false, { sameSite: 'strict' });
		} 
		else {
			document.body.classList.add('fontsize');
			Cookies.set('bigfont', true, { sameSite: 'strict' });
		}
	}
	const turnContrast = () => {
		if (Cookies.get("contrast") === "true") {
			document.body.classList.remove('contrast');
			Cookies.set('contrast', false, { sameSite: 'strict' });
		}
		else {
			document.body.classList.add('contrast');
			Cookies.set('contrast', true, { sameSite: 'strict' });
		}
		window.location.reload(true);
	}

	return (
		<div className="accessibility-toolbar d-sm-inline-flex">
			<button className="accessibility-icon" onClick={turnBigFont} href="">{FontIcon()}</button>
			<button className="accessibility-icon" onClick={turnContrast} href="">{ContrastIcon()}</button>
		</div>
	);
}

export default AccessibilityToolbar;