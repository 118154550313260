import { Fragment, useEffect, useState } from 'react';
import { withAuthenticationRequired } from "react-oidc-context";
import UserList from './Tables/UserList';
import Loading from './UI/LoadingSpinner';
import UserModal from './Modals/UserModal';
import UserCreditModal from './Modals/UserCreditModal';
import { useTranslation } from 'react-i18next';
import Pagination from './UI/Pagination';
import Cookies from 'js-cookie';
import { useAuth } from "react-oidc-context";

const AdminPanel = ({ admin }) => {
	const auth = useAuth();
	const [usersData, setUsersData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(getCountPerPageCookie());
	const [modalOpen, setModalOpen] = useState(false);
	const [creditModalOpen, setCreditModalOpen] = useState(false);
	const [editModalUser, setEditModalUser] = useState(null);
	const { t } = useTranslation();

	const fetchUsers = () => {
		(async () => {
			try {
				setLoading(true);
				const token = auth.user?.access_token;
				const response = await fetch("/users", {
					method: 'POST',
					mode: 'cors',
					headers: {
						"Authorization": "Bearer " + token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ offset: page * count, count: count })
				});
				setUsersData(await response.json());
			} catch (e) { setError(e); }
			finally { setLoading(false); }
		})();
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { fetchUsers() }, [page, count]);

	return (
		<Fragment>
			{usersData &&
				<Fragment>
					<div className="table-header">
						<h1>{t('users')}</h1>
						<button
							className="btn btn-primary"
							onClick={() => {
								setModalOpen(true);
								setEditModalUser(null);
							}}>+ {t('addUser')}</button>
					</div>

					{(usersData && usersData.total > 0) &&
						<UserList
							users={usersData.users}
							onCreditEdit={(user) => {
								setEditModalUser(user);
								setCreditModalOpen(true);
							}}
							onEditUser={(user) => {
								setEditModalUser(user);
								setModalOpen(true);
							}} />
					}

					<Pagination
						page={page}
						perPage={count}
						onPageChange={setPage}
						onPerPageChange={setCount}
						totalCount={usersData.total}
					/>
				</Fragment>
			}

			{loading && <Loading />}

			{error && <p>{error}</p>}

			{modalOpen &&
				<UserModal
					auth={auth}
					admin={admin}
					user={editModalUser}
					onClose={(refresh) => {
						setModalOpen(false);
						if(refresh) fetchUsers();
					}}
				/>}
			{creditModalOpen &&
				<UserCreditModal
					auth={auth}
					user={editModalUser}
					onClose={(refresh) => {
						setCreditModalOpen(false);
						if (refresh) fetchUsers();
					}}
				/>}
		</Fragment>
	);
}

const getCountPerPageCookie = () => {
	const count = Cookies.get("perPageCount");
	return count ? count : 10;
}

export default withAuthenticationRequired(AdminPanel, {
	onRedirecting: () => <Loading />
});