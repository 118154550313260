import React, { Fragment } from 'react';
import { useAuth } from "react-oidc-context";
import { useTranslation } from 'react-i18next';
import ModalResult from './ModalResultFragment';

const FileDownloadModal = ({ file, onClose }) => {
	const auth = useAuth();
	const { t } = useTranslation();
	const [state, setState] = React.useState("ready");
	const [format, setFormat] = React.useState("AudioDoc");
	const [error, setError] = React.useState(null);

	const getFile = async (id) => {
		setState("loading");
		try {
			let filename = "";
			const token = auth.user?.access_token;
			fetch(`/arm/audio-file/${id}/result?type=${format}`, {
				mode: 'cors',
				headers: { "Authorization": "Bearer " + token }
			}).then(response => {
				if (!response.ok) switch (response.status) {
					case 404: throw new Error("errorDownloadFileNotFound");
					default: throw new Error("errorDownloadFile");
				} 
				filename = response.headers
					.get("content-disposition")
					.match(/filename\*=UTF-8''([^;\n]*)/)[1];
				return response.blob();
			}).then(blob => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				onClose();
			})
		} catch (e) { setState("error"); setError(e); }
	}

	return (
		<div className="modal" onClick={onClose}>
			<div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t('download')}</h5>
					</div>

					{state === "ready" &&
						<Fragment>
							<div className="modal-body">
								<p>{t('file')}: {file.name}</p>
								<label htmlFor="lang-select">{t('chooseFormat')}:</label>
								<select
									className="form-select"
									id="lang-select"
									defaultValue={format}
									onClick={(e) => setFormat(e.target.value)}
								>
									<option value="AudioDoc">AudioDoc</option>
									<option value="Txt">Txt</option>
									<option value="Vtt">Vtt</option>
									<option value="Elan">Elan</option>
								</select>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => onClose()} >{t('cancel')}</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={() => { getFile(file.id) }} >{t('download')}</button>
							</div>
						</Fragment>
					}
					<ModalResult
						state={state}
						error={error}
						onClose={onClose} />
				</div>
			</div>
		</div>
	);
}

export default FileDownloadModal;