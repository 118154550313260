import { useEffect, useState, Fragment } from 'react';
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { ApplicationPaths, ApplicationName } from './App';

const AuthContext = ({ children }) => {
	const [config, setConfig] = useState(null);

	useEffect(() => {
		(async () => {
			let response = await fetch(ApplicationPaths.AuthorizationClientConfigurationUrl);
			if (!response.ok) { throw new Error(`Could not load settings for '${ApplicationName}'`); }
			const config = {
				automaticSilentRenew: true,
				userStore: new WebStorageStateStore({ store: window.localStorage }),
				...await response.json()
			};
			setConfig(config);
		})();
	}, []);

	return (
		<Fragment>
			{config && <AuthProvider {...config}>
				{children}
			</AuthProvider>}
		</Fragment>
	);
}

export default AuthContext;
