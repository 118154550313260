import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from "react-oidc-context";
import { ApplicationPaths } from '../App';

const LandingPage = () => {
	const { t } = useTranslation();
	const [arm, setArm] = useState(null);
	const auth = useAuth();

	useEffect(() => { getVersion(); }, []);

	const getVersion = async () => {
		let response = await fetch(ApplicationPaths.AppSettings);
		if (!response.ok) throw new Error(`Could not load settings`);
		let settings = await response.json();
		setArm(settings.versionLabel);
	}

	return (
		<div className="landing-page">
			{arm && <Fragment>
				<div className="landing-page-about">
					<div className="landing-page-title">
						<h1>{t(`${arm}_title`)}</h1>
						<h2>{t(`${arm}_subtitle`)}</h2>
					</div>
					<img alt="" src={require(`../images/landing_page_bg.jpg`)} />
					<div>
						<h3>{t(`${arm}_description_title`)}</h3>
						<p>{t(`${arm}_description`)}</p>
					</div>
					
				</div>
				<div className="landing-page-working">
					<h3>{t(`how_it_works`)}</h3>
					<div className="landing-page-cards">
						<div>
							<h4>{t('app_description_1_1')}</h4>
							<p>{t('app_description_1_2')}</p>
						</div>
						<div>
							<h4>{t('app_description_2_1')}</h4>
							<p>{t('app_description_2_2')}</p>
						</div>
						<div>
							<h4>{t('app_description_3_1')}</h4>
							<p>{t('app_description_3_2')}</p>
						</div>
						<div>
							<h4>{t('app_description_4_1')}</h4>
							<p>{t('app_description_4_2')}</p>
						</div>
					</div>
					<h5>{t('app_billing')}</h5>
					<a href={t('tariff_webpage')}>{t('app_tariff')}</a>
					<button
						className="btn btn-primary" 
						onClick = {() => auth.signinRedirect()}
					>{t('loginOrRegister')}</button>
				</div>
			</Fragment>}
		</div>
	)
}

export default LandingPage;