import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ModalResult from './ModalResultFragment';
import TimeInput from '../UI/TimeInput';
import { ArrayToTimeSpan, TimeSpanToArray } from '../Util/TimeSpanFunctions';
import { useAuth } from "react-oidc-context";

const UserAddModal = ({ user, admin, onClose }) => {
	const auth = useAuth();
	const { t } = useTranslation();
	const [state, setState] = React.useState("ready");
	const [error, setError] = React.useState(null);
	const [title, setTitle] = React.useState(user ? "editUser" : "addUser");
	const [email, setEmail] = React.useState(user ? user.email : "");
	const [emailError, setEmailError] = React.useState(null);
	const [credits, setCredits] = React.useState(user ? TimeSpanToArray(user.credits) : [0,0,0,0,0]);
	const [enabled, setEnabled] = React.useState(user ? user.enabled : true);

	const addUser = async () => {
		if (validateEmail()) {
			setState("loading");
			try {
				const token = auth.user?.access_token;
				const response = await fetch(`/user`, {
					method: 'POST',
					headers: {
						"Authorization": "Bearer " + token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						email: email,
						credit: ArrayToTimeSpan(credits),
						id: admin.id
					})
				});
				if (response.ok) onClose(true);
				else throw new Error("errorAddUser");
			} catch (e) { setState("error"); setError(e); }
		}
	}

	const editUser = async () => {
		if (validateEmail()) {
			setState("loading");
			try {
				const token = auth.user?.access_token;
				const response = await fetch(`/user/${user.id}`, {
					method: 'PUT',
					headers: {
						"Authorization": "Bearer " + token,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						id: user.id,
						email: email,
						credits: ArrayToTimeSpan(credits),
						enabled: enabled
					})
				});
				if (response.ok) onClose(true);
				else switch (response.status) {
					case 404: throw new Error("errorEditUserNotFound");
					default: throw new Error("errorEditUser");
				}
			} catch (e) { setState("error"); setError(e); }	
		}
	}

	const deleteUser = async () => {
		setState("loading");
		try {
			const token = auth.user?.access_token;
			const response = await fetch(`/user/${user.id}`, {
				method: 'DELETE',
				headers: { "Authorization": "Bearer " + token }
			});
			if (response.ok) onClose(true);
			else switch (response.status) {
				case 404: throw new Error("errorDeleteUserNotFound");
				default: throw new Error("errorDeleteUser");
			}
			
		} catch (e) { setState("error"); setError(e); }	
	}

	const validateEmail = () => {
		if (email.trim() === '') setEmailError("emailEmpty");
		else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return true;
		else setEmailError("emailNotValid");

		return false;
	}

	return (
		<div className="modal" onClick={onClose}>
			<div className="modal-dialog" role="document" onClick={e => e.stopPropagation()}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{t(`${title}`)}</h5>
						{state === "ready" && user &&
							<button
								className="btn btn-danger"
								onClick={() => { setTitle("deleteUser"); setState("delete"); }}
							>{t('delete')}</button>
						}
					</div>
					{state === "ready" && <Fragment>
						<div className="modal-body">
							<form>
								<div className="mb-3">
									<label htmlFor="email" className="form-label">{t('email')}:</label>
									<input
										type="text"
										className={`form-control ${emailError ? 'is-invalid' : ''}`}
										id="email"
										value={email}
										onChange={(e) => { setEmail(e.target.value); setEmailError(null); }}
									/>
									<small className="text-danger">{t(emailError)}</small>
								</div>
								<TimeInput credits={credits} onChange={(cr) => setCredits(cr)} />
								{user && <div className="form-check form-switch">
									<input
										type="checkbox"
										role="switch"
										className="form-check-input"
										id="enabled"
										checked={enabled}
										onChange={(e) => { setEnabled(!enabled) }}
									/>
									<label htmlFor="enabled" className="form-check-label">{t('enabled')}</label>
								</div>}
							</form>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => onClose()} >{t('cancel')}</button>
							{user && <button
								type="button"
								className="btn btn-primary"
								onClick={() => editUser()} >{t('confirm')}</button>}
							{!user && <button
								type="button"
								className="btn btn-primary"
								onClick={() => addUser()} >{t('add')}</button>}
						</div>
					</Fragment>}
					{state === "delete" && <Fragment>
						<div className="modal-body">{t('areYouSureDeleteUser')}</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => {
									setState("ready");
									setTitle(user ? "editUser" : "addUser")
								}} >{t('cancel')}</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => deleteUser()} >{t('delete')}</button>
						</div>
					</Fragment>}
					<ModalResult
						state={state}
						error={error}
						onClose={onClose} />
				</div>
			</div>
		</div>
	);
}

export default UserAddModal;
