import { withTranslation } from 'react-i18next';

const TimeInput = ({ t, credits, onChange }) => {
	return (
		<div className="mb-3">
			<label htmlFor="credit" className="form-label">{t('credit')}:</label>
			<div className="row">
				<div className="col">
					<label htmlFor="credit" className="form-label">{t('hours')}:</label>
					<input
						type="number"
						className="form-control"
						id="credit"
						value={(Number(credits[0]) * 24) + Number(credits[1])}
						onChange={(e) => {
							if (/^[0-9]*$|^$/.test(e.target.value))
								onChange([
									Math.floor(e.target.value / 24),
									Number(e.target.value % 24),
									credits[2],
									credits[3],
									credits[4],
								]);
						}}
					/>
				</div>
				<div className="col">
					<label htmlFor="credit" className="form-label">{t('minutes')}:</label>
					<input
						type="number"
						className="form-control"
						id="credit"
						value={Number(credits[2])}
						onChange={(e) => {
							if (/^(0?\d|[1-5]\d)$|^$/.test(e.target.value))
								onChange([
									credits[0],
									credits[1],
									Number(e.target.value),
									credits[3],
									credits[4],
								]);
						}}
					/>
				</div>
				<div className="col">
					<label htmlFor="credit" className="form-label">{t('secands')}:</label>
					<input
						type="number"
						className="form-control"
						id="credit"
						value={Number(credits[3])}
						onChange={(e) => {
							if (/^(0?\d|[1-5]\d)$|^$/.test(e.target.value))
								onChange([
									credits[0],
									credits[1],
									credits[2],
									Number(e.target.value),
									credits[4],
								]);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(TimeInput);