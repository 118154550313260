import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { ArrowIcon } from './Icons'
import Cookies from 'js-cookie';

const Pagination = (props) => {
	const pageCount = Math.ceil(props.totalCount / props.perPage);
	const onPageChange = (newPage) => { props.onPageChange(newPage) };
	const onPerPageChange = (perPage) => {
		props.onPerPageChange(perPage)
		Cookies.set('perPageCount', perPage, { sameSite: 'strict' });
	};
	const { t } = useTranslation();

	const prevPagesNum = () => {
		if (props.page < 5 || pageCount <= 9) return props.page;
		if ((props.page + 5) > pageCount) return 6 - (pageCount - (props.page + 1));
		return 2;
	}

	const nextPagesNum = () => {
		if (((props.page + 6) > pageCount) || (pageCount <= 9)) return pageCount - (props.page + 1);
		if (props.page < 4) return 6 - props.page;
		return 2;
	}

	const hidePrevPage = (i) => {
		if (i === prevPagesNum() - 1) return false;
		if (nextPagesNum() === 0 && i === prevPagesNum() - 2) return false;
		else return true;
	}

	const hideNextPage = (i) => {
		if (i === 0) return false;
		if (prevPagesNum() === 0 && i === 1) return false;
		return true;
	}

	return (
		<Fragment>
			<div className="pagination">
				{pageCount > 1 &&
					<div className="container">
						<button className="arrows" onClick={() => onPageChange(props.page - 1)} disabled={(props.page === 0)}>
							<ArrowIcon rotate={0} />
						</button>

						{((props.page > 4) && (pageCount > 9)) && <>
							<button className="xs-hidden" onClick={() => onPageChange(0)}>1</button>
							<button className="deliminator" disabled>...</button>
						</>}

						{[...Array(prevPagesNum())].map((el, index) =>
							<button
								key={`L${index}`}
								className={hidePrevPage(index) ? "xs-hidden" : ""}
								onClick={() => onPageChange(props.page - prevPagesNum() + index)}>
								{props.page - prevPagesNum() + index + 1}
							</button>
						)}

						<button className="current_page" disabled={true}>{props.page + 1}</button>

						{[...Array(nextPagesNum())].map((el, index) =>
							<button
								key={`R${index}`}
								className={hideNextPage(index) ? "xs-hidden" : ""}
								onClick={() => onPageChange(props.page + index + 1)}>
								{props.page + index + 2}
							</button>
						)}

						{(((pageCount - props.page) > 5) && (pageCount > 9)) && <>
							<button className="deliminator" disabled>...</button>
							<button className="xs-hidden" onClick={() => onPageChange(pageCount - 1)}>{pageCount}</button>
						</>}

						<button className="arrows" onClick={() => onPageChange(props.page + 1)} disabled={props.page + 1 === pageCount}>
							<ArrowIcon rotate={180} />
						</button>
					</div >
				}

				{props.totalCount > 10 &&
					<div className="item-count">
						<div>
							<label>{t('perPage')}: </label>
							<select
								className="form-select"
								onInput={e => { onPageChange(0); onPerPageChange(e.target.value); }}
							>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={50}>100</option>
							</select>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
}

export default Pagination;