import { TimeSpanToArray, ArrayToTimeSpan } from '../Util/TimeSpanFunctions';

//Returns time in format hh:mm:ss
 const TimeSpanDisplay = (span) => {
	const arr = TimeSpanToArray(span);
	return ArrayToTimeSpan([
		0, //days
		(arr[0] * 24) + arr[1], //hours
		arr[2], //minutes
		arr[3], //seconds
		0 //miliseconds
	])
}

export default TimeSpanDisplay;