import { useState, Fragment, useEffect } from "react";
import { Collapse, Container, Navbar} from 'reactstrap';
import AccessibilityToolbar from './UI/AccessibilityToolbar';
import { MenuIcon } from './UI/Icons';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import LanguageSwitcher from './translations/LanguageSwitcher';
import { useAuth } from "react-oidc-context";
import { ApplicationPaths, ApplicationName } from "../App";
import TimeSpanDisplay from './UI/TimeSpanDisplay';

const NavMenu = ({ user, refreshUse }) => {
	const auth = useAuth();
	const [toggleNavbar, setToggleNavbar] = useState(false);
	const contrast = Cookies.get("contrast") === 'true' ? "white" : "black";
	const { t, i18n } = useTranslation();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { refreshUse() }, [auth]);

	return (
		<Navbar className="site-header navbar-expand-lg navbar-toggleable-lg">
			<Container>
				<a href="/" ><img src={require(`../images/logo_${contrast}.png`)} alt="Dariah.lab" /></a>

				<button onClick={() => { setToggleNavbar(!toggleNavbar) }} className="mr-2 navbar-toggler" >
					<MenuIcon open={toggleNavbar} />
				</button>

				<Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={toggleNavbar} navbar>
					<ul className="navbar-nav flex-grow">
						{user && <Fragment>
							{user.is_admin ? (
								<button onClick={() => window.location.href = '/admin'}>{t('admin')}</button>
							) : (
									<button>{t('credit')}: {TimeSpanDisplay(user.credits)}</button>
							)}
						</Fragment>}
						<LanguageSwitcher />
						{auth && auth.isAuthenticated ?
							<Fragment>
								<button onClick={() => redirectToProfile()}>{t('account')}</button>
								<button onClick={() => auth.signoutRedirect()}>{t('logout')}</button>
							</Fragment>
							:
							<button onClick={() => auth.signinRedirect()}>{t('loginOrRegister')}</button>
						}
						<AccessibilityToolbar />
					</ul>
				</Collapse>

				<div className="header_ue">
					<img
						alt="ue logotype "
						className="invert_img"
						src={require(`../images/ue_${i18n.resolvedLanguage}_${contrast}.png`)} />
				</div>
			</Container>
		</Navbar>
	);
};

export default NavMenu;

const redirectToProfile = async () => {
	const response = await fetch(ApplicationPaths.AuthorizationClientConfigurationUrl);
	if (!response.ok) throw new Error(`Could not load settings for '${ApplicationName}'`);
	const settings = await response.json();
	window.location.replace(`${settings.authority}/account/#/personal-info?referrer=${settings.client_id}`);
}