import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnabledIcon, DisabledIcon } from './../UI/Icons';
import TimeSpanDisplay from '../UI/TimeSpanDisplay';

const UserList = (props) => {
	const { t } = useTranslation();

	const utcToDate = (utc) => {
		const date = new Date(utc);
		return date.toLocaleString([], { dateStyle: 'short'});
	}

	return (
		<table className='table table-striped table-borderless' aria-labelledby="tabelLabel">
			<thead>
				<tr>
					<th>E-mail</th>
					<th>{t('created')}</th>
					<th>{t('credit')}</th>
					<th>{t('enabled')}</th>
				</tr>
			</thead>
			<tbody>
				{props.users && props.users.map((user) =>
					<tr key={user.id}>
						<td data-label="E-mail">{user.email} {user.is_admin ? "(admin)" : ""}</td>
						<td data-label={t('created')}>{utcToDate(user.created)}</td>
						<td data-label={t('credit')}>{TimeSpanDisplay(user.credits)}</td>
						<td data-label={t('enabled')}>{user.enabled ? <EnabledIcon /> : <DisabledIcon />}</td>
						<td className="d-lg-flex justify-content-end">
							<button
								className="btn btn-secondary"
								onClick={() => props.onEditUser(user)}>
								{t('edit')}
							</button>
							<button
								className="btn btn-primary"
								onClick={() => props.onCreditEdit(user)}>
								{t('addCredits')}
							</button>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
}

export default UserList;