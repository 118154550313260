import { Fragment } from "react";

export const MenuIcon = (props) => (
	<Fragment>
		{!props.open && <svg viewBox="0 0 512 512" height="40px" width="40px" >
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeMiterlimit={10}
				strokeWidth={48}
				d="M88 152h336M88 256h336M88 360h336"
			/>
		</svg>}
		{props.open && <svg viewBox="0 0 21 21" height="40px" width="40px" >
			<g fillRule="evenodd" stroke="currentColor">
				<path d="M15.5 15.5l-10-10zM15.5 5.5l-10 10" strokeWidth={2} />
			</g>
		</svg>}
	</Fragment>
);

export const ReloadIcon = (props) => (
	<Fragment>
		{!props.loading && <svg
				viewBox="0 0 20 20"
				fill="currentColor"
				height="1.5rem"
				width="1.5rem"
			>
				<path d="M14.66 15.66A8 8 0 1117 10h-2a6 6 0 10-1.76 4.24l1.42 1.42zM12 10h8l-4 4-4-4z" />
			</svg>
		}
		{props.loading && <div
			className="spinner-border text-light"
			role="status"
		/>}
	</Fragment>
);

export const ArrowIcon = (props) => (
	<svg
		viewBox="0 0 24.32 24.32"
		width="3em"
		height="3em" fill="#425ba6"
		transform={`rotate(${props.rotate})`}
	>
		<path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" strokeWidth={0} />
	</svg>
);

export const FontIcon = (rpolps) => (
		<svg viewBox="0 0 16 16" height="1em" width="1em" >
			<path d="M1 8h6v2H5v6H3v-6H1zm14-4h-3.934v12H8.933V4H4.999V2h10z" />
		</svg>
);

export const ContrastIcon = () => (
		<svg viewBox="0 0 16 16" height="1em" width="1em">
			<path d="M8 0a8 8 0 100 16A8 8 0 008 0zM2 8a6 6 0 016-6v12a6 6 0 01-6-6z"/>
		</svg>
);

export const EnabledIcon = () => (
	<svg width="1.5em" height="1.5em" viewBox="0 0 15 15" fill="none">
		<path d="M4 7.5L7 10l4-5" stroke="#00725D" strokeWidth={2} />
	</svg>
);

export const DisabledIcon = () => (
	<svg width="1.5em" height="1.5em" viewBox="0 0 21 21" fill="none">
		<path d="M15.5 15.5l-10-10zM15.5 5.5l-10 10" stroke="#cf2e2e" strokeWidth={2} />
	</svg>
);