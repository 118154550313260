import React, { Fragment, useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import Home from "./components/Home";
import Loading from './components/UI/LoadingSpinner';
import LandingPage from "./components/LandingPage";
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
import AdminPanel from "./components/AdminPanel";

const App = () => {
	const auth = useAuth();
	const [user, setUser] = useState(null);

	const fetchUser = async () => {
		if (auth.isAuthenticated) {
			const response = await fetch("/arm/user", {
				headers: { Authorization: `Bearer ${auth.user.access_token}` },
			});
			setUser(await response.json());
		}
	};

	const cleanPath = () => window.history.replaceState("", "", "/");

	useEffect(() => {
		if (window.location.pathname === ApplicationPaths.LogoutCallback) cleanPath();
		else if (auth.isAuthenticated && window.location.pathname === ApplicationPaths.LoginCallback) cleanPath();
	}, [auth]);

	if (auth.isLoading) return <Loading />;

	return (
		<Fragment>
			<div id="main-wrapper">
				<NavMenu user={user} refreshUse={fetchUser} />
				<main className="container">
					<BrowserRouter
						isAuthenticated={auth.isAuthenticated}
						fetchUser={fetchUser}
						user={user}
					/>
				</main>
				<Footer />
			</div>
		</Fragment>
	);
}

export default App;

const BrowserRouter = ({ isAuthenticated, fetchUser, user }) => {
	if (isAuthenticated) {
		if (window.location.pathname === ApplicationPaths.Admin) return <AdminPanel admin={user} />
		else return <Home fetchUser={fetchUser} />
	} else return <LandingPage />
}

export const ApplicationName = 'dariah_arm_app';
export const ApplicationPaths = {
	DefaultLoginRedirectPath: '/',
	AuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
	AppSettings: `_configuration/settings`,
	LoginCallback: `/authentication/login-callback`,
	LogoutCallback: `/authentication/logout-callback`,
	Account: `/account`,
	Admin: `/admin`,
};