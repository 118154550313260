import React from "react";
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	const langs = {
		pl: { nativeName: 'polski' },
		en: { nativeName: 'English' }
	};

	const getInverseLang = (lang) => {
		if (lang === "pl") return "en";
		else return "pl";
	}

	const handleLanguageChange = () => {
		i18n.changeLanguage(getInverseLang(i18n.resolvedLanguage));
	};

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<button onClick={handleLanguageChange}>
			{langs[getInverseLang(i18n.resolvedLanguage)].nativeName}
		</button>
	);
};

export default LanguageSwitcher;