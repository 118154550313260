import { Fragment, useEffect, useState } from 'react';
import FilesList from './Tables/FilesList';
import FileDeleteModal from './Modals/FileDeleteModal';
import FileAddModal from './Modals/FileAddModal';
import FileDownloadModal from './Modals/FileDownloadModal';
import { useTranslation } from 'react-i18next';
import { ReloadIcon } from './UI/Icons';
import { useAuth } from "react-oidc-context";

const Home = ({ fetchUser }) => {
	const { t } = useTranslation();
	const auth = useAuth();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [modal, setModal] = useState(null);
	const [modalFile, setModalFile] = useState(null);

	const fetchFiles = async () => {
		if (auth.isAuthenticated) {
			setError(null);
			setLoading(true);
			try {
				const token = auth.user?.access_token;
				const response = await fetch("/arm/audio-file", {
					headers: { Authorization: `Bearer ${token}`, },
				});
				setData(await response.json());
			} catch (e) { setError(e); setData(null); }
			finally { setLoading(false); }
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { fetchFiles() }, [auth]);

	return (
		<Fragment>
			<div className="table-header">
				<div className="d-flex  align-items-center">
					<h1>{t('audioFiles')}</h1>
					<button
						className="btn btn-primary reload_btn"
						onClick={() => { fetchUser(); fetchFiles();  }}
					>
						<ReloadIcon loading={loading} />
					</button>
				</div>
				<button className="btn btn-primary" onClick={() => {setModal("Add")}}>
					+ {t('addFiles')}
				</button>
			</div>

			{error && <p className="text-danger">{t('Error: filesError')}</p>}

			{(data && data.count > 0) && <FilesList
				files={data.files}
				onDelete={(file) => {
					setModal("Delete");
					setModalFile(file);
				}}
				onDownload={(file) => {
					setModal("Download");
					setModalFile(file);
				}}
			/>}

			{modal === "Add"  && <FileAddModal
				onClose={(refresh) => {
					setModal(null);
					if (refresh) {
						fetchFiles();
						fetchUser();
					}
				}}
			/>}

			{modal === "Delete"  && <FileDeleteModal
				file={modalFile}
				onClose={(refresh) => {
					setModal(null);
					if (refresh) fetchFiles();
				}}
			/>}

			{modal === "Download"  && <FileDownloadModal
				file={modalFile}
				onClose={() => {setModal(null)}}
			/>}
		</Fragment>
	);
}

export default Home;